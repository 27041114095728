<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import ContactModal from './partials/contact-modal.vue';
import { useMetaStore } from '~/stores/meta.store';
import { useSettingsStore } from '~/stores/settings.store';
import helper from '~/resources/js/libraries/helper';

const props = defineProps<{
  data: {
    [key: string]: any;
  };
}>();

const config = useRuntimeConfig()

const isEmailFormOpen = ref(false);

const router = useRouter();
const settingsStore = useSettingsStore();
const settings = settingsStore.settings;

const hasLocation = computed(() => {
	return props.data.location_on_map
		&& props.data.location_on_map.lng
		&& props.data.location_on_map.lat
});
const hasGallery = computed(() => {
	return props.data.gallery_pictures
		&& props.data.gallery_pictures.length;
});

const sections = ref([
	{
		id: 'highlights',
		show: props.data.highlight,
		titleKey: 'projects_highlights',
		highlight: true,
	},
	{
		id: 'overview',
		show: props.data.overview,
		titleKey: 'projects_overview',
	},
	{
		id: 'payment-plan',
		show: props.data.payment_plan,
		titleKey: 'projects_payment_plan',
	},
	{
		id: 'floor-plans',
		show: props.data.floor_plans,
		titleKey: 'projects_floor_plans',
	},
	{
		id: 'photo-gallery',
		show: hasGallery,
		titleKey: 'projects_photo_gallery',
	},
	{
		id: 'location-map',
		show: hasLocation,
		titleKey: 'projects_location_map',
	},
	{
		id: 'pdf-brochure',
		show: props.data.brochure,
		titleKey: 'projects_pdf_brochure',
	},
	{
		id: 'project-units',
		show: props.data.project_units.length,
		titleKey: 'projects_project-units'
	}
]);

const metaStore = useMetaStore();
metaStore.setMeta(props.data.meta);
const currency = helper.cookies.currency();

const scrollTo = (id: string) => {
	sections.value.forEach(section => section.highlight = false);
	const section = sections.value.find(section => section.id === id);
	if (section) {
		section.highlight = true;
	}
	router.push({ hash: `#${id}` });

}

const handleImageUrl = (imageUrl: string) => {
	return [new URL(`${imageUrl}?w=800&h=600&fit=cover`, config.public.mediaUrl as string).href];
}
</script>

<template>
	<LayoutAppHead></LayoutAppHead>

	<NuxtLayout name="mobile-base">

		<template #mobile-header>
			<MobileHeader title="Project Details" sub-title="">
				<template #header-left>
					<mobile-back-button></mobile-back-button>
				</template>
				<template #header-right>
					<mobile-share-button></mobile-share-button>
				</template>
			</MobileHeader>
		</template>

		<template #mobile-footer></template>

		<div class="mb-32">
			<div class="flex flex-col item-center px-4 mt-2">
				<div v-if="hasGallery" ref="photo-gallery" id="photo-gallery" class="mb-8">
					<lightbox :images="data.gallery_pictures" displayStyle="mobile" />
				</div>

				<div class="relative mb-4">
					<div>
						<div class="my-3">
							<h2 class="text-indigo-900 text-2xl font-bold my-t">{{ data.name }}</h2>
							<h3 class="text-base mb-1 text-gray-500">{{ data.city_name }}, {{ data.country_name }}</h3>
						</div>

						<!-- Content Sections -->
						<div v-if="data.highlight" ref="highlights" id="highlights" class="my-3 border-b pb-3">
							<h4 class="text-xl text-indigo-900 font-bold mb-3">{{ $t('client.projects_highlights') }}</h4>
							<div v-html="data.highlight"></div>
						</div>
						<div v-if="data.overview" ref="overview" id="overview" class="my-3 border-b pb-3">
							<h4 class="text-xl text-indigo-900 font-bold mb-3">{{ $t('client.projects_overview') }}</h4>
							<div class="text-gray-600" v-html="data.overview"></div>
						</div>
						<div v-if="data.payment_plan" ref="payment-plan" id="payment-plan" class="my-3 border-b">
							<h4 class="text-xl text-indigo-900 font-bold mb-3">{{ $t('client.projects_payment_plan') }}</h4>
							<div v-html="data.payment_plan"></div>
						</div>
						<div v-if="data.floor_plans" ref="floor-plans" id="floor-plans" class="my-3 border-b">
							<h4 class="text-xl text-indigo-900 font-bold mb-3">{{ $t('client.projects_floor_plans') }}</h4>
							<div v-html="data.floor_plans"></div>
						</div>
						<div v-if="hasLocation" ref="location-map" id="location-map" class="my-3 border-b">
							<h4 class="text-xl text-indigo-900 font-bold mb-3">{{ $t('client.projects_location_map') }}</h4>
							<shared-map-i-frame :lng="data.location_on_map.lng" :lat="data.location_on_map.lat"
								addMark />
						</div>
						<div v-if="data.brochure" ref="pdf-brochure" id="pdf-brochure" class="my-3 border-b">
							<h4 class="text-xl text-indigo-900 font-bold mb-3">{{ $t('client.projects_pdf_brochure') }}</h4>
							<a :href="data.brochure" target="_blank">
								<button type="button"
									class="uppercase rounded-md bg-indigo-900 px-7 py-4 text-sm text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
									<i class="fa-solid fa-file-pdf fa-lg me-2"></i>
									{{ $t('client.projects_download_pdf_brochure') }}
								</button>
							</a>
						</div>
					</div>
				</div>

				<!-- Projects Units -->
				<div ref="project-units" id="project-units" v-if="data.project_units.length" class="">
					<h4 class="text-xl text-indigo-900 font-bold mb-5">
						{{ $t('client.projects_project-units') }}
						<span class="text-gray-500 text-base">({{ data.project_units.length }})</span>
					</h4>
					<div class="col-span-2 mt-3" v-for="project_unit in data.project_units">
						<div class="border-2 bg-white rounded-lg p-4">
							<div class="flex flex-col gap-3 justify-between">
								<div v-if="project_unit.picture">
								  <img class="w-full h-30 rounded-lg  object-cover object-top" :src="`${project_unit.picture}?w=1728&650`" :alt="project_unit.name" >
								</div>
				          	    <div class="flex items-center justify-between">
										<div>
											<p class="text-gray-700">
											{{ $t('client.category') }}:
											<span class="font-bold text-gray-900">{{ project_unit.category_name }}</span>
											</p>
											<p class="text-gray-700">
											{{ $t('client.unit_code') }}:
											<span class="font-bold text-gray-900">{{ project_unit.unit_code }}</span>
											</p>
											<p class="text-gray-700">
											{{ $t('client.units_count') }}:
											<span class="font-bold text-gray-900">{{ project_unit.units_count }}</span>
											</p>
										</div>
										<p class="font-bold text-2xl text-indigo-900 ms-auto">
											{{ helper.formatPrice(project_unit.price) }}
											{{ currency.symbol }}
										</p>
				                   </div>
						    	</div>
							<div class="flex text-center mt-4" v-if="project_unit.features.base_features">
								<div class="grid grid-flow-row border-r px-3 last:border-0"
									v-for="feature in project_unit.features.base_features" :key="feature.slug">
									<template v-if="feature.input_type == 'image'">
												<div>
													<lightbox :images="handleImageUrl(feature.value)" displayStyle="singleImage" />
													<div class="text-xs text-gray-500 mt-3">{{ feature.name }}</div>
												</div>
											</template>
											<template v-else>
												<div class="flex flex-col items-center justify-center">
													<span class="mb-3 w-14 h-14 flex justify-center items-center bg-gray-200 rounded-full">
														<i :class="`${feature.icon} fa-xl text-gray-400`"></i>
													</span>
													<span class="text-xs text-gray-500">
														{{ feature.value }}
														{{ feature.input_type === 'number' ? feature.name : '' }}
													</span>
												</div>
											</template>
								</div>
							</div>
							<div class="p-3 text-sm text-gray-600 border-t"
								v-if="project_unit.features?.multi_features.length">
								<div class="flex flex-col mb-3" v-for="feature in project_unit.features.multi_features">
									<h5 class="font-medium text-indigo-800 mb-3 text-xl">{{ feature.name }}</h5>
									<div class="grid grid-cols-4 gap-3">
										<div class="flex items-center" v-for="sub_feature in feature.value">
											<i :class="sub_feature.icon"></i>
											<span class="text-gray-600 text-sm ms-1">{{ sub_feature.value }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Sticky Bottom Bar -->
		<div class="fixed bottom-0 bg-white p-4 w-full z-50">
			<div class="flex items-center justify-between gap-3 w-full">
				<div class="flex flex-col">
					<span class="text-xs text-gray-500">{{ $t('client.starting_from') }}</span>
					<h1 class="text-xl font-bold text-indigo-900">
						{{ currency.symbol }}
						{{ new Intl.NumberFormat().format(data.start_price) }}
					</h1>
				</div>
				<div class="w-1/2 ms-auto flex items-center justify-end">
					<a v-if="settings.contact_phones?.length" :href="`tel:${settings.contact_phones[0]}`"
						class="flex items-center px-3 w-2/3 justify-center text-center border border-white py-2.5 rounded-lg bg-indigo-200 text-indigo-800">
						<i class="fa-solid fa-phone me-2"></i> Call
					</a>
					<a :href="settings.social_media_whatsapp" target="_blank"
						class="px-6 text-center border border-white py-2.5 rounded-lg bg-[#daebe2] text-[#28b06e]">
						<i class="fa-brands fa-whatsapp fa-lg"></i>
					</a>
				</div>
			</div>
		</div>

		<main>
			<!-- TODO: Contact Us Form -->
			<div class="relative mx-auto" style="height: 40rem;">
				<img class="w-full object-cover" :src="`${data.background}?w=680&1280&fit=cover`" :alt="data.name" style="height: 40rem;">
				<div class="absolute inset-0 bg-gradient-to-b from-slate-800 to-slate-500 opacity-50 rounded-md"></div>
				<div class="absolute bottom-0 p-5 flex flex-col justify-center items-center w-full">
					<h2 class="text-white text-3xl font-medium tracking-wide mb-3">
						{{ $t('client.contact_us') }}
					</h2>
					<layout-email-form />
				</div>
			</div>

			<ContactModal v-model="isEmailFormOpen" />
		</main>
	</NuxtLayout>
</template>
